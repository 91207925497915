<template>
	<div class="contain">
		<searchBar :searchInput="false"></searchBar>
		<div class="bgf">
			<br />
			<div class="fs_24 center color_333 padding_20 bottom_link">
				个人信息修改
			</div>
			<el-form :model="userInfo" :rules="rules" ref="ruleForm" :hide-required-asterisk="true" label-position="top" class="form">
				<el-row style="width:330px;margin:0 auto;">
					<el-col :span="24">
						<el-form-item label="头像" prop="department">
							<el-upload :action="`${baseApi}/api/v2/upload/image?filename=file&type=company`" :headers="headers" accept=".jpg,.jpeg,.png" :show-file-list="false" :on-success="handleLogoSuccess" :before-upload="beforeLogoUpload" :on-progress="handleLogoProgress">
								<div class="file-img" v-if="userInfo.avatar">
									<img :src="userInfo.avatar">
									<p class="tip-text flex_cen el-icon-edit-outline">修改</p>
								</div>
								<div class="flex_cen" v-else>
									<img src="@/assets/imgs/icon/img-icon.png" class="img-icon" v-if="avatarProgress==0">
									<el-progress type="circle" :percentage="avatarProgress" width="90" class="mt_5" v-else></el-progress>
								</div>
							</el-upload>
						</el-form-item>
					</el-col>
					<!-- <el-col :span="24">
						<el-form-item label="部门" prop="dept_name">
							<el-input type="text" placeholder="请填写部门" v-model="userInfo.dept_name" maxlength="8"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="职位" prop="job_name">
							<el-input type="text" placeholder="请填写职位" v-model="userInfo.job_name" maxlength="8"></el-input>
						</el-form-item>
					</el-col> -->
					<el-col :span="24">
						<el-form-item label="手机号">
							<el-input type="number" v-model.trim="userInfo.mobile" readonly class="mobile-input">
								<template #suffix>
									<!-- <p class="color btn fs_12 suffix-btn" @click="showChange">修改</p> -->
								</template>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item>
					<el-button class="submit fs_18" type="primary" :loading="loading" @click="submitForm('ruleForm')">确认修改</el-button>
				</el-form-item>
			</el-form>
		</div>
		<change-mobile-dialog ref="changeMobileDialog"></change-mobile-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { getToken, removeToken, l_setItem } from '@/common/cache'
import changeMobileDialog from './components/changeMobile-dialog'
export default {
	name: "AccountInfo",
	components: { changeMobileDialog },
	data() {
		return {
			loading: false,
			baseApi: process.env.VUE_APP_API,
			headers: {
				'Authorization': 'Bearer ' + getToken()
			},
			avatar_tmp: '',
			avatarProgress: 0, // 头像 上传进度
			rules: {
				avatar: [
					{ required: true, message: "请上传头像", trigger: "blur" }
				]
				// dept_name: [
				// 	{ required: true, message: "请填写部门", trigger: "blur" }
				// ],
				// job_name: [
				// 	{ required: true, message: "请填写职位", trigger: "blur" }
				// ]
			}
		}
	},
	computed: {
		...mapGetters({
			userInfo: 'getUserInfo'
		})
	},
	methods: {
		...mapActions({
			setUserInfo: 'SETUSERINFO'
		}),
		handleLogoSuccess(res, file) {
			if (res.code !== 200) {
				if (res.code === 410000) {
					this.$message.error('请登录')
					removeToken()
					this.$router.push('/login')
				} else {
					this.$message.error(res.msg)
				}
			} else {
				this.avatar_tmp = URL.createObjectURL(file.raw)
				this.userInfo.avatar = res.data.url
				this.avatarProgress = 0
			}
		},
		beforeLogoUpload(file) {
			this.avatar_tmp = ''
			this.userInfo.avatar = ''
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
			const isLt2M = file.size / 1024 / 1024 < 5
			if (!isJPG) {
				this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!')
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 5MB!')
			}
			return isJPG && isLt2M
		},
		handleLogoProgress(event) {
			this.avatarProgress = Math.floor(event.percent)
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true
					this.$axios({
						url: '/ent/v3/usercenter/info',
						method: 'PUT',
						data: this.userInfo,
						shake: true,
						hideLoading: true
					}).then(res => {
						setTimeout(() => {
							this.loading = false
						}, 900)
						if (res.code === 200) {
							this.$message.success(res.msg)
							this.setUserInfo(this.userInfo)
							l_setItem("USERINFO", this.userInfo)
						}
					})
				}
			})
		},
		showChange() {
			this.$refs.changeMobileDialog.dialogVisible = true
		}
	}
}
</script>

<style lang="less" scoped>
.contain {
	min-height: 58vh;
}
/deep/.form {
	padding: 30px 100px 90px;
	.el-upload {
		width: 100px;
		height: 100px;
		border-radius: 16px;
		border: 1px dashed #e3e3e3;
		background-color: #f9f9f9;
		overflow: hidden;
		position: relative;
		.tip-text {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 3;
			color: #fff;
			background-color: rgba(0,0,0,.3);
			display: none;
		}
		.file-img {
			width: auto;
			&:hover {
				.tip-text {
					display: flex;
				}
			}
		}
	}
	.img-icon {
		width: 20px;
		height: 20px;
	}
	.el-form-item__label {
		font-size: 16px;
		padding: 0 !important;
	}
	.el-input__inner {
		height: 44px;
		background-color: #f9f9f9;
		border-radius: 0px;
		width: 100% !important;
	}

	.submit {
		margin: 0 auto;
		width: 200px;
		line-height: 26px;
		height: 52px;
		display: block;
		margin-top: 50px;
		border-radius: 2px;
	}
	.suffix-btn {
		display: none;
		line-height: 44px;
		margin-right: 10px;
	}
	.mobile-input:hover {
		.suffix-btn {
			display: block;
		}
	}
}
/deep/.el-upload-list .el-upload-list__item,
/deep/.el-upload {
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>

<template>
	<div class='telephone'>
		<el-dialog v-model="dialogVisible" :lock-scroll="true" :before-close="close" width="460px" top="20vh">
			<template v-slot:title>
				<div class="flex_c">
					<p class="fs_18 bold">修改手机号</p>
				</div>
			</template>
			<div class="content">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :hide-required-asterisk="true" label-position="top">
					<el-form-item label="当前手机号" prop="phone">
						<el-input placeholder="请输入手机号码" maxlength="11" type="number" v-model.trim="ruleForm.phone"></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="captcha">
						<el-input placeholder="请输入验证码" maxlength="6" type="number" v-model.trim="ruleForm.captcha" @keyup.enter="submit">
							<template #suffix>
								<el-button type="text" class="btn" v-if="!sandSuccess" :loading="sandLoading" @click="sendCode">发送验证码</el-button>
								<el-button type="text" class="btn" v-else>{{sandTime}}s后重新获取</el-button>
							</template>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<template v-slot:footer>
				<div class="flex_around">
					<el-button type="info" size="medium" @click="close">取 消</el-button>
					<el-button type="primary" size="medium" :loading="loading" @click="submitForm('ruleForm')">确认修改</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import { isPhone } from '@/common/validate'
export default {
	data() {
		var checkMobile = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入手机号码'))
			} else if (!isPhone(value)) {
				callback(new Error('请输入正确的手机号码'))
			} else {
				callback()
			}
		}
		return {
			dialogVisible: false,
			sandSuccess: false,
			loading: false,
			sandLoading: false,
			sandTime: 60,
			ruleForm: {
				phone: '',
				captcha: ''
			},
			rules: {
				phone: [
					{ validator: checkMobile, trigger: 'blur' }
				],
				captcha: [/* 动态效验 */]
			}
		}
	},
	methods: {
		sendCode() {
			this.rules.captcha = []
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.sandLoading = true
					this.$axios({
						url: '/api/v2/account/verify?type=login',
						method: 'POST',
						data: {
							phone: this.ruleForm.phone,
							type: 'login'
						},
						no_carryToken: true,
						hideLoading: true,
						shake: true
					}).then(res => {
						if (res.code === 200) {
							this.$message.success(res.msg)
							this.sandSuccess = true
							this.countTime()
							this.rules.captcha.push({ required: true, message: '请输入验证码', trigger: 'blur' })
						}else {
							this.sandLoading = false
						}
					})
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		countTime() {
			var currentTime = 60
			var interval = setInterval(() => {
				this.sandTime = currentTime--
				if (currentTime < 0) {
					clearInterval(interval) // 清除计时器
					this.rules.captcha = []
					this.sandSuccess = false
					this.sandTime = 60
				}
			}, 1000)
		},
		close() {
			this.dialogVisible = false
		},
		submitForm(formName) {
			this.rules.captcha.push({ required: true, message: '请输入验证码', trigger: 'blur' })
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true
					this.$axios({
						url: '',
						method: 'PUT',
						data: this.userInfo,
						shake: true,
						hideLoading: true
					}).then(res => {
						if (res.code === 200) {
							this.$message.success('修改成功')
							this.close()
							this.setUserInfo(this.userInfo)
						}else {
							setTimeout(() => {
								this.loading = false
							}, 900)
						}
					})
				} else {
					console.log("error submit!!")
					return false
				}
			})
		}
	}
}
</script>

<style lang='less' scoped>
.el-icon-warning {
	color: #FF9900;
}
/deep/.el-dialog__header {
	padding: 20px;
}
/deep/.el-dialog__body {
	padding: 0;
}
.content {
	border-top: 1px solid #ddd;
	padding: 30px;
}
/deep/.el-button {
	width: 162px;
	height: 42px;
	font-size: 16px;
}
/deep/.el-input__inner {
	height: 44px;
	background-color: #f9f9f9;
	border-radius: 0px;
	width: 100% !important;
}
/deep/.el-form-item__label {
	font-size: 16px;
	padding: 0 !important;
}
.el-input {
	.btn {
		color: #5ab0ff;
		line-height: 26px;
	}
}
</style>